import React from 'react'
import Slider from "react-slick";
import Card from "./Card"
import image1 from '../assets/InteligenciaArtificial.svg'
import image2 from '../assets/GemelosDigitales.svg'
import image3 from '../assets/IngenieriaServicios.svg'
import image4 from '../assets/DashboardApps.svg'
import image5 from '../assets/FotografiaLidar.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/cards.css'

const cards = [
    {
        id: 1,
        title: 'Inteligencia Artificial',
        content: 'Nuestras Soluciones de AI y machine learning se aplican a datos espaciales capturados por diferentes ...',
        image: image1,
        link:'/IA'
    },
    {
        id: 2,
        title: 'Gemelos digitales BIM con sistemas de mapeamiento móvil',
        content: 'Capture solo una vez, utilice muchas veces y para diferentes ...',
        image: image2,
        link:'/Bim'
    },
    {
        id: 3,
        title: 'Ingeniería de servicios públicos del subsuelo',
        content: 'Contamos con la mejor tecnología de identificación de infraestructura subterranea para aplicaciones ...',
        image: image3,
        link:'/IS'
    },
    {
        id: 4,
        title: 'Dashboard y apps',
        content: 'Hemos contruido un ecosistema modular para resolver retos o problemas que van desde ...',
        image: image4,
        link:'/DA'
    },
    {
        id: 5,
        title: 'Fotografía y lidar aerotransportado',
        content: 'Nuestros servicios cubren toda la cadena de produccion de datos espaciales ...',
        image: image5,
        link:'Fotografia'
    }
]

export default function Cards() {
    var settings = {
        autoplay: false,
        dots: true,
        infinite: false,
        speed: 800,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    speed: 500,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 500,
                    dots: true
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 500,
                    dots: true
                }
            }
        ]
    };
    return (
        <div className='container py-4' name='soluciones' id='soluciones'>
            <div className='row py-2'>
                <h2 className="letraPlaneto text-dark">NUESTRAS</h2>
                <h1 className="letraPlaneto">SOLUCIONES</h1>
            </div>
            <Slider {...settings}>
                {
                    cards.map(card => (
                        <div className='px-2 py-4' key={card.id}>
                            <Card card={card} />
                        </div>
                    ))
                }

            </Slider>
        </div>
    );
}
