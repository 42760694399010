import React, { useState } from 'react';
import Slider from "react-slick";
import cover from '../assets/CoverGemelo.jpg';
import img1 from '../assets/GemeloA.jpg';
import img2 from '../assets/GemeloB.jpg';
import img3 from '../assets/GemeloC.jpg';
import img4 from '../assets/GemeloD.jpg';
const settings = {
    arrows:false,
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    row: 2,
    vertical: true,
    verticalSwiping: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesPerRow: 1,
    initialSlide: 0,
};
function GemelosDigitales() {
    const [mostrar, setMostrar] = useState(false);
    return (
        <div className=''>
            <img src={cover} className='img-fluid imagenIa' width='100%' />
            <div className='container-fluid'>
                <div className='row row-cols-1 row-cols-md-2'>
                    <div className='col col-sm-6 col-md-6 py-4 letraMontserrat lh-base'>
                        <p className='subtitulos fw-fw-bold h4'> Imágenes panoramicar 360° de alta resolucion</p>
                        <p className='fst-italic'>"capture solo una vez, utilice muchas veces y para diferentes propósitos"...</p>
                        <p>Nuestras soluciones de mapeamiento móvil, que <b className='remarcado fw-bold'>integra sensores de navegación avanzando con imágenes panorámicas 360°</b> de alta <b className='remarcado fw-bold'>
                            resolución</b> y sensores <b className='remarcado fw-bold'>LIDAR</b> que generan una nube de puntos georreferenciados (x,y,z), representa una <b className='remarcado fw-bold'>técnicas avanzadas para el mapeamiento</b>
                            e inventario dinámico del entorno de <b className='remarcado fw-bold'>plantas industriales, caminos,</b> ciudades, creando <b className='remarcado fw-bold'>pantas industriales, caminos,</b>ciudades, creando
                            <b className='remarcado fw-bold'> gemelos digitales</b> de alta precisión y detalle en cuestión de horas.</p>
                        <p>
                            A partir de esta información creando inventarios de elementos tan diversos como válvulas o infraestructura urbana  que "entregadas" en
                            plataformas intuitivas basadas en la web permiten a nuetros clientes interactuar e incorporar fácilmente en flujos de trabajo y procesos
                            de toma de decisiónes, datos espaciales de alta complejidad.
                        </p>
                        <div className={mostrar ? '' : 'd-none'}>
                            <p className='py-4'>
                                Estas soluciones permiten la <b className='remarcado fw-bold'>documentación digital objetiva</b> de infraestructura de diversa naturaleza a través del tiempo a usuarios con
                                objetivos tan diferentes como <b className='remarcado fw-bold'>propietarios, constructores o supervisores</b> y que desde ubicaciones desconcentradas pueden interactuar en
                                tiempos reales con la misma base documental, tal como si se encontrarán todos juntos en terreno.<br />
                                Constituyen un elemento clave en las estrategias de <b className='remarcado fw-bold'>transformación digital </b>de nuestros clientes.
                            </p>
                        </div>
                        <buton className='btn rounded-1 degradado letraMontserratsemibold' onClick={() => setMostrar(!mostrar)}>
                            {mostrar ? 'Ver menos' : 'Ver más'}
                        </buton>
                    </div>
                    <div className='col col-sm-6 col-md-6'>

                        <Slider {...settings}>

                            <div className='py-1'>
                                <img src={img1} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-1'>
                                <img src={img2} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-1'>
                                <img src={img3} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-1'>
                                <img src={img4} className='img-fluid imagenIa py-2' width='100%' />
                            </div>

                        </Slider>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default GemelosDigitales