import { BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './App.css';
import Footer from './components/Footer';
//Componentes
import InicioVista from './components/InicioVista';
import InteligenciaArtificial from './components/InteligenciaArtificial';
import GemelosDigitales from './components/GemelosDigitales';
import IngeniaeriaServicios from './components/IngeniaeriaServicios';
import FotografiaLidar from './components/FotografiaLidar';
import DashboardApps from './components/DashboardApps';
import Electricidad from './components/Electricidad';
import Construccion from './components/Construccion';
import Gobierno from './components/Gobierno';
import Forestal from './components/Forestal';
import EnDesarrollo from './components/EnDesarrollo';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<InicioVista />} />
        <Route path='/IA' element={<InteligenciaArtificial />} />
        <Route path='/Bim' element={<GemelosDigitales />} />
        <Route path='/IS' element={<IngeniaeriaServicios />}/>
        <Route path='/Fotografia' element={<FotografiaLidar />}/>
        <Route path='/DA' element={<DashboardApps />}/>
        <Route path='/Electricidad' element={<Electricidad />}/>
        <Route path='/Construccion' element={<Construccion />}/>
        <Route path='/Gobierno' element={<Gobierno />}/>
        <Route path='/Forestal' element={<Forestal />}/>
        <Route path='/PreguntasFre' element={<EnDesarrollo />}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
