import React, { useState } from 'react'
import Slider from "react-slick";
import cover from '../assets/CoverDashboard.jpg'
import img1 from '../assets/DashboardA.jpg'
import img2 from '../assets/DashboardB.jpg'
import img3 from '../assets/DashboardC.jpg'
import img4 from '../assets/DashboardD.jpg'

const settings = {
    arrows:false,
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    row: 2,
    vertical: true,
    verticalSwiping: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesPerRow: 1,
    initialSlide: 0,
  };

function DashboardApps() {
    const [mostrar,setMostrar]=useState(false);
    return (
        <div className=''>
            <img src={cover} className='img-fluid imagenIa' width='100%' />
            <div className='container-fluid'>
                <div className='row row-cols-1 row-cols-md-2'>
                    <div className='col col-sm-6 col-md-6 py-4 letraMontserrat lh-base'>
                        <p className='subtitulos h4 fw-bold'>Detecte, asigne, reporte y solucione.</p>
                        <p>Nuestro ecosistema modular ayuda a comprender, analizar y visualizar
                         <b className='remarcado fw-bold'> de acuerdo con sus ubicaciones</b> las operaciones de nuestros clientes, estableciendo al mismo tiempo vasos 
                         comunicantes entre la información operativa, comercial y financiera.</p>
                        <div className={mostrar?'':'d-none'}>
                            <p className='py-4'>
                                Desplegamos la información en niveles de agregación a requerimiento y necesidad del usuario mediante plataformas basadas 
                                en la web y apps que hacen de <b className='remarcado fw-bold'>vínculo entre la oficina y el terreno.</b> Esta información incorpora la dimensión espacial (ubicación) 
                                lo que permite la mejor <b className='remarcado fw-bold'>compresión del entorno</b> en el que se desarrollan las <b className='remarcado fw-bold'>operaciones</b> de nuestros clientes y, en última
                                instancia, optimizando la toma de decisiones. Se estima que al menos el 80% de todos los datos son de naturaleza geográfica, 
                                dado que la mayoría de la información que nos rodea puede ser georreferenciada. 
                            </p>
                            <p>
                                Nuestros módulos permiten <b className='remarcado fw-bold'>detectar desviaciones, asignarlas</b> a equipos de terreno para su <b className='remarcado fw-bold'>verificación/solución </b> 
                                que podrán <b className='remarcado fw-bold'>reportar el status</b> de sus asignaciones y/o requerir reasignaciones, todo dentro de un <b className='remarcado fw-bold'>flujo de trabajo 100% digital.</b>
                            </p>
                        </div>
                        <buton className='btn rounded-1 degradado letraMontserratsemibold' onClick={()=>setMostrar(!mostrar)}>
                            {mostrar?'Ver menos':'Ver más'}
                        </buton>
                    </div>
                    <div className='col col-sm-6 col-md-6 bg-light'>
                        <Slider {...settings}>
                            <div className='py-2'>
                                <img src={img1} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-2'>
                                <img src={img2} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-2'>
                                <img src={img3} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-2'>
                                <img src={img4} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardApps