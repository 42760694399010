import React from 'react'
import './css/footer.css'
import './css/tipografia.css'
export default function Footer() {
  return (
    <footer id='footer'>
      <div className="col-md-12 text-center text-dark py-4">
        <hr className="mb-4" />
        <img src="/assets/img/marca.svg" className="img-fluid py-3 col-md-4 col-sm-4" alt="Responsive image" />
        <h4 id="letrafooter">Las Condes, Santiago - Chile</h4>
        <h4 className='py-2' id="letrafooter">+562 32341267 - contacto@marvich-ai.cl</h4>
        <hr className="mb-4" />
        <section className="mb-4 text-center">
          <a className="btn mybtn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"><i className="bi bi-facebook"></i></a>
          <a
            className="btn  mybtn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"><i className="bi bi-twitter"></i></a>
          <a
            className="btn  mybtn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"><i className="bi bi-google"></i></a>
          <a
            className="btn mybtn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"><i className="bi bi-instagram"></i></a>
          <a
            className="btn mybtn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"><i className="bi bi-linkedin"></i></a>
          <a
            className="btn mybtn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"><i className="bi bi-github"></i></a>
        </section>
      </div>
    </footer>
  )
}
