import React, { useState } from 'react'
import cover from '../assets/CoverGobierno.jpg'
import img1 from '../assets/GobiernoA.jpg'
function Gobierno() {
    const [mostrar,setMostrar]=useState(false)
    return (
        <div className=''>
            <img src={cover} className='img-fluid imagenIa' width='100%' />
            <div className='container-fluid'>
                <div className='row row-cols-1 row-cols-md-2'>
                    <div className='col col-sm-6 col-md-6 py-4 letraMontserrat lh-base'>
                        <p>
                            Nuestra solución enteramente <b className='remarcado fw-bold'>basada en la web,</b> ayuda a municipios y empresas prestadoras de servicios públicos,
                             a incorporar a sus usuarios como parte activa de sus actividades, y paralelamente, cuenten con información oportuna y 
                             agregada para la toma de decisiones gerenciales mejorando continuamente la calidad del servicio prestado.
                        </p>
                        <div className={mostrar?'':'d-none'}>
                            <p className='py-4'>
                                Nuestras soluciones se <b className='remarcado fw-bold'>acceden a través de navegadores de internet</b> y están basadas en tecnologías 
                                de código abierto por lo que <b className='remarcado fw-bold'>no requieren inversiones adicionales en software,</b> ni bases de datos como tampoco
                                en hardware para acceder a la información. Pueden ser <b className='remarcado fw-bold'>operadas</b> en las instalaciones de la empresa, en la
                                <b className='remarcado fw-bold'> nube o en un esquema mixto.</b>
                            </p>
                        </div>
                        <buton className='btn brounded-1 degradado letraMontserratsemibold' onClick={()=>setMostrar(!mostrar)}>
                            {mostrar?'Ver menos':'Ver más'}
                        </buton>
                    </div>
                    <div className='col col-sm-6 col-md-6'>
                        <img src={img1} className='img-fluid imagenIa py-2' width='100%' />
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gobierno