import React, { useState } from 'react'
import cover from '../assets/CoverConstruccion.jpg'
import img1 from '../assets/ConstruccionA.jpg'
import img2 from '../assets/ConstruccionB.jpg'
function Construccion() {
    const [mostrar,setMostrar]=useState(false);
    return (
        <div className=''>
            <img src={cover} className='img-fluid imagenIa' width='100%' />
            <div className='container-fluid'>
                <div className='row row-cols-1 row-cols-md-2'>
                    <div className='col col-sm-6 col-md-6 py-4 letraMontserrat lh-base'>
                        <p className='subtitulos fw-bold h4'>Plataforma de construcción digital</p>
                        <p>Gracias a nuestro ecosistema de partnerships con empresas líderes globales en diferentes campos, ofrecemos 
                            una plataforma de monitoreo de proyectos de construcción de <b className='remarcado fw-bold'>caminos, ductos, vías férreas, tendidos de agua y alcantarillado, líneas eléctricas.</b></p>
                        <div className={mostrar?'':'d-none'}>
                            <p className='py-4'>
                                El objetivo de nuestra plataforma es permitir la <b className='remarcado fw-bold'>evaluación digital y remota</b> del estado de avance 
                                de una obra de construcción, ayudando de esta manera a sus propietarios, financiadores, diseñadores, planificadores, 
                                programadores, contratistas generales, supervisores y operadores para ser más eficientes.
                            </p>
                            <p>
                                Mediante una plataforma basada en la web, nuestros clientes monitorean el progreso de sus obras de construcción, 
                                optimizando un proyecto en todas sus etapas. Los análisis multidimensionales que se ofrecen proporcionan información 
                                valiosa con indicadores (KPI’s) de ingeniería para <b className='remarcado fw-bold'>optimizar, controlar y documentar</b> la planificación, 
                                ejecución y entrega de un proyecto
                            </p>
                            <p>
                                Se trata de una solución de <b className='remarcado fw-bold'>extremo-a-extremo</b> que empieza con la captura de fotografías aéreas desde drones, 
                                continúa con la generación automática de modelos 3D y as-builts del sitio de construcción y que termina en la elaboración 
                                de informes de <b className='remarcado fw-bold'>ingeniería, cálculos, seguimiento del progreso</b> y garantía de <b className='remarcado fw-bold'>calidad.</b>
                            </p>
                        </div>
                        <buton className='btn rounded-1 degradado letraMontserratsemibold' onClick={()=>setMostrar(!mostrar)}>
                            {mostrar?'Ver menos':'Ver más'}
                        </buton>
                    </div>
                    <div className='col col-sm-6 col-md-6'>
                        <img src={img1} className='img-fluid imagenIa py-2' width='100%' />
                        <img src={img2} className='img-fluid imagenIa py-2' width='100%' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Construccion
