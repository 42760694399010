import React from 'react'
import Header from './Header'

function EnDesarrollo() {
    return (
        <div>
            <div className="container text-center py-2">
                <img src="/assets/img/marca.svg" className="img-fluid col-md-6 col-sm-4" alt="Responsive image" />
            </div>
            <div className='container-fluid py-2'>
                <div className="card ">
                    <img src="/assets/img/construccion.gif" class="card-img-top w-50 mx-auto" alt="..."/>
                    <div className="card-body bg-warning text-center">
                        <h5 className="card-title">Esta Parte esta en Construcción</h5>
                        <p className="card-text">Saludos, dentro de poco tendremos concluido esta parte.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnDesarrollo
