import React from 'react'
import { Link } from 'react-router-dom'
import './css/card.css'
export default function Card(props) {
  const {card}=props;
  return (
    <div className='card shadow text-center miCard'>
        <div className='container overflow'>
            <img src={card.image} className='imgCard center-block d-block mx-auto'/>
        </div>
        <div className='card-body'>
            <h4 className='card-title letraMontserratbold'>{card.title}</h4>
            <p className='card-text text-primary letraMontserrat'>
               {card.content}
            </p>
            <Link to={card.link} className='btn mybtn rounded-1 letraMontserratsemibold text-dark'>Mas Informacion</Link>
        </div>
    </div>
  )
}
