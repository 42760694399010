import React, { useState } from 'react'
import cover from '../assets/CoverElectricidad.jpg'
import img1 from '../assets/ElectricidadA.jpg'
import img2 from '../assets/ElectricidadB.jpg'
function Electricidad() {
    const [mostrar,setMostrar]=useState(false);
    return (
        <div className=''>
            <img src={cover} className='img-fluid imagenIa' width='100%' />
            <div className='container-fluid'>
                <div className='row row-cols-1 row-cols-md-2'>
                    <div className='col col-sm-6 col-md-6 py-4 letraMontserrat lh-base'>
                        <p className='subtitulos h4 fw-bold'>
                            Datos satelitales + AI para la gestión de la gestión de la vegetación
                        </p>
                        <p>Gracias a nuestro ecosistema de partnerships con empresas líderes globales en diferentes campos, ofrecemos el servicio 
                            de monitoreo de amenazas a la integridad <b className='remarcado fw-bold' >líneas eléctricas, ductos</b> y <b className='remarcado fw-bold' >vías férreas</b> debido al crecimiento de la
                            <b className='remarcado fw-bold' > vegetación,</b> la <b className='remarcado fw-bold' >interferencia de terceros</b> y la <b className='remarcado fw-bold' >deformación del suelo.</b></p>
                        <div className={mostrar?'':'d-none'}>
                            <p className='py-4'>
                                La plataforma de monitoreo de infraestructura “en corredores” se basa en <b className='remarcado fw-bold' >imágenes satelitales 2D y 3D</b> y 
                                <b className='remarcado fw-bold' > algoritmos de inteligencia artificial</b> para detectar desviaciones en plazos muy breves a escalas que van 
                                desde unos cientos de kilómetros hasta todas las líneas de un país. En base a los requerimientos de nuestros 
                                clientes procesamos datos satelitales de media o alta resolución y producimos resultados en 2 y 3D. Los resultados 
                                en la forma de <b className='remarcado fw-bold' >alerta temprana</b> son entregados mediante <b className='remarcado fw-bold' >plataformas basadas en la web y apps </b>
                                que pueden integrarse con los sistemas en actual uso.
                            </p>
                        </div>
                        <buton className='btn rounded-1 degradado letraMontserratsemibold' onClick={()=>setMostrar(!mostrar)}>
                            {mostrar?'Ver menos':'Ver más'}
                        </buton>
                    </div>
                    <div className='col col-sm-6 col-md-6'>
                        <img src={img1} className='img-fluid imagenIa py-2' width='100%' />
                        <img src={img2} className='img-fluid imagenIa py-2' width='100%' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Electricidad