import React, { useState } from 'react'
import Slider from "react-slick";
import cover from '../assets/CoverServicio.jpg'
import img1 from '../assets/ServicioA.jpg'
import img2 from '../assets/ServicioB.jpg'
import img3 from '../assets/ServicioC.jpg'
import img4 from '../assets/ServicioD.jpg'
import img5 from '../assets/ServicioE.jpg'
import img6 from '../assets/ServicioF.jpg'
const settings = {
    arrows:false,
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    row: 2,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    speed: 500,
    slidesPerRow: 1,
    initialSlide: 0,
  };
function IngeniaeriaServicios() {
    const [mostrar, setMostrar] = useState(false);
    return (
        <div className=''>
            <img src={cover} className='img-fluid imagenIa' width='100%' />
            <div className='container-fluid'>
                <div className='row row-cols-1 row-cols-md-2'>
                    <div className='col col-sm-6 col-md-6 py-4 letraMontserrat lh-base'>
                        <p className='subtitulos fw-bold h4'> Analice y conozca antes de cavar.</p>
                        <p>La <b className='remarcado fw-bold'>Infraestructura soterrada</b> ha constituido un elemento de <b className='remarcado fw-bold'>riesgo</b> en todas las obras que impliquen <b className='remarcado fw-bold'>excavaciones.</b>
                            Ya sea por la inexistencia, extravío y/o desactualización de infraestructura soterrada, las empresas que deben realizar excavaciones
                            se encuentran a menudo forzadas a jugar a la ruleta navegando el subsuelo con visibilidad nula.</p>
                        <div className={mostrar ? '' : 'd-none'}>
                            <p className='py-4'>
                                Para solucionar este problema, contamos con tecnología de punta para la prestación de servicios de <b className='remarcado fw-bold'>ingeniería del subsuelo.</b>
                                Nuestra solución de alta productividad se basa en un conjunto modular masivo de antenas radar para el <b className='remarcado fw-bold'>mapeo 3D del subsuelo.</b>
                                Entregamos el equivalente de tomografías de detalle de la infraestructura soterrada.
                            </p>
                        </div>
                        <buton className='btn rounded-1 degradado letraMontserratsemibold' onClick={() => setMostrar(!mostrar)}>
                            {mostrar ? 'Ver menos' : 'Ver más'}
                        </buton>
                    </div>
                    <div className='col col-sm-6 col-md-6'>
                        <Slider {...settings}>
                            <div className='py-2'>
                                <img src={img1} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-2'>
                                <img src={img2} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-2'>
                                <img src={img3} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-2'>
                                <img src={img4} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-2'>
                                <img src={img5} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-2'>
                                <img src={img6} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IngeniaeriaServicios