import React from 'react';
import {Link} from 'react-router-dom';
import './css/header.css'

export default function Header() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark headerColor">
            <div className="container-fluid ">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarTogglerDemo03">
                    <ul className="navbar-nav text-end">
                        <li className="nav-item px-3 text-end">
                            <a className='badge text-end'>01</a>
                            <hr className='lineaMenu'/>
                            <a className="nav-link h6 text-white" aria-current="page" href="#">HOME</a>
                        </li>
                        <li className="nav-item px-3 text-end">
                            <span className='badge text-end'>02</span>
                            <hr className='lineaMenu'/>
                            <a href="#soluciones" className="nav-link h6 text-white" aria-current="page" >SOLUCIONES</a>
                        </li>
                        <li className="nav-item px-3 text-end">
                            <span className='badge text-end'>03</span>
                            <hr className='lineaMenu'/>
                            <a className="nav-link h6 text-white" href="#industrias">INDUSTRIAS</a>
                        </li>
                        <li className="nav-item px-3 text-end">
                            <span className='badge text-end'>04</span>
                            <hr className='lineaMenu'/>
                            <a className="nav-link h6 text-white"  data-bs-toggle="modal" data-bs-target="#exampleModal" >CONTACTO</a>
                        </li>
                        <li className="nav-item px-3 text-end">
                            <span className='badge text-end'>05</span>
                            <hr className='lineaMenu'/>
                            <Link className="nav-link h6 text-white" to='/PreguntasFre'>PREGUNTAS FRECUENTES</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
