import React, { useState } from 'react'
import cover from '../assets/CoverFotografia.jpg'
import img1 from '../assets/FotografiaA.jpg'
//import img2 from '../assets/FotografiaB.jpg'

function FotografiaLidar() {
    const [mostrar, setMostrar] = useState(false);
    return (
        <div className=''>
            <img src={cover} className='img-fluid imagenIa' width='100%' />
            <div className='container-fluid'>
                <div className='row row-cols-1 row-cols-md-2'>
                    <div className='col col-sm-6 col-md-6 py-4 letraMontserrat lh-base'>
                        <p className='subtitulos h4 fw-bold'>Capture las características del territorio de su operaciones</p>
                        <p>Contamos con <b className='remarcado fw-bold'>sensores remotos de alta precisíon</b> en plataformas que nos permiten alcanzar resoluciones
                            (GSD) en función a las necesidades de nuestros clientes desde algunos <b className='remarcado fw-bold'>multímetros</b>
                            hasta metros, y que abarcan el espectro de ondas visibles 
                            <b className='remarcado fw-bold'>(RGB), Infrarrojo, Radar, LIDAR.</b></p>
                        <div className={mostrar ? '' : 'd-none'}>
                            <p className='py-4'>
                                Nuestras aeronaves son del tipo pilotadas o no pilotadas <b className='remarcado fw-bold'>(drones)</b> y tenemos también acuerdos con proveedores de imágenes
                                satelitales. Esto nos permite gran flexibilidad para adecuarnos a los requerimientos especificos de nuestros clientes.
                            </p>
                        </div>
                        <buton className='btn rounded-1 degradado letraMontserratsemibold' onClick={() => setMostrar(!mostrar)}>
                            {mostrar ? 'Ver menos' : 'Ver más'}
                        </buton>
                    </div>
                    <div className='col col-sm-6 col-md-6'>
                        <img src={img1} className='img-fluid imagenIa py-2' width='100%' />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FotografiaLidar