import React, { useState } from 'react';
import Slider from "react-slick";
import cover from '../assets/CoverForestal.jpg';
import img1 from '../assets/ForestalA.jpg';
import img2 from '../assets/ForestalC.jpg';
import img3 from '../assets/ForestalB.jpg';

const settings = {
    arrows:false,
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    row: 2,
    vertical: true,
    verticalSwiping: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesPerRow: 1,
    initialSlide: 0,
  };
function Forestal() {
    const [mostrar,setMostrar]=useState(false);
    return (
        <div className=''>
            <img src={cover} className='img-fluid imagenIa' width='100%' />
            <div className='container-fluid'>
                <div className='row row-cols-1 row-cols-md-2'>
                    <div className='col col-sm-6 col-md-6 py-4 letraMontserrat lh-base'>
                        <p className='subtitulos fw-bold h4'>Silvicultura de precisión mediante inteligencia artificial geoespacial</p>
                        <p> 
                            La industria forestal enfrenta <b className='remarcado fw-bold'>desafíos económicos, sociales y medioambientales</b> que configuran un
                             escenario dinámico de difícil y costosa gestión. Por esto, los líderes en la industria buscan nuevas formas
                              de monitorear y gestionar su patrimonio forestal y sus operaciones para reducir costos y maximizar el valor.
                        </p>
                        <div className={mostrar?'':'d-none'}>
                            <p className='py-4'>
                                Tradicionalmente, los silvicultores se han basado en estudios aéreos costosos y, por tanto, poco frecuentes
                                y geográficamente limitados, respaldados por evaluaciones por muestreo en terreno para gestionar los rodales
                                y monitorear el impacto de desviaciones y perturbaciones. 
                            </p>
                            <p>
                                Nuestra solución de I<b className='remarcado fw-bold'>nteligencia Artificial Geoespacial</b> busca cambiar el enfoque tradicional de un análisis por muestreo
                                a un <b className='remarcado fw-bold'>análisis del universo,</b> con una metodología de captura masiva de datos espaciales de alta resolución y los algoritmos
                                de inteligencia artificial más avanzados del mercado para el <b className='remarcado fw-bold'>análisis y detección de eventos que se desvían de un comportamiento estándar</b>
                                o esperado, con el objetivo de dirigir los recursos de terreno al lugar correcto en el momento <b className='remarcado fw-bold'>adecuado para maximizar su impacto.</b>
                            </p>
                            <p>
                                Monitoreamos de forma remota integralmente el ciclo forestal. Cubrimos la <b className='remarcado fw-bold'>cosecha,</b> las faenas de <b className='remarcado fw-bold'>habilitación, plantación, prendimiento, raleo,</b>
                                control de <b className='remarcado fw-bold'>malezas,</b> prevención de <b className='remarcado fw-bold'>incendios</b> entre otras.
                            </p>
                            <p>
                                El conocimiento frecuente y preciso que brindamos a nuestros clientes, les permite la gestión dinámica de su <b className='remarcado fw-bold'>patrimonio forestal,</b>
                                e impulsa un ciclo de <b className='remarcado fw-bold'>mejoramiento continuo</b> de sus operaciones, mejora de la productividad y disminución de costos.
                            </p>
                        </div>
                        <buton className='btn rounded-1 degradado letraMontserratsemibold' onClick={()=>setMostrar(!mostrar)}>
                            {mostrar?'Ver menos':'Ver más'}
                        </buton>
                    </div>
                    <div className='col col-sm-6 col-md-6'>
                        <Slider {...settings}>
                            <div className='py-2'>
                                <img src={img1} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-2'>
                                <img src={img2} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                            <div className='py-2'>
                                <img src={img3} className='img-fluid imagenIa py-2' width='100%' />
                            </div>
                        </Slider>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forestal