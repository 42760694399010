import React, { useState } from 'react'
import cover from '../assets/CoverTexto.jpg'
import img1 from '../assets/ia1.jpg'
import img2 from '../assets/ia2.jpg'
function InteligenciaArtificial() {
    const [mostrar, setMostrar] = useState(false)
    return (
        <div className=''>
            <img src={cover} className='img-fluid imagenIa w-100' />
            <div className='container-fluid'>
                <div className='row row-cols-1 row-cols-md-2'>
                    <div className='col col-sm-6 col-md-6 py-4 letraMontserrat lh-base'>
                        <p>Nuestras soluciones de <b className='remarcado fw-bold'>AI y machine learning</b> se aplican a datos especiales capturados por diferentes sensores remotos
                            (Cámaras fotogramétricas RGB y NIR, escaner LIDAR aéreos y terrestres moviles, imágenes satelitales multiespectrales y Radar)
                            muy rápidamente (desde algunos minutos hasta unas pocas horas) y a escala masiva. <b className='remarcado fw-bold'>detectar y vectorizar todo tipo de elementos
                                visibles en los datos espaciales,</b> tales como plantines, maleza, lápidas, maquinaria, pavimento en mal estado, edificios, entre
                            muchos otros y esto a una escala que va desde una parcela hasta un país entero.</p>
                        <div class={mostrar ? '' : "d-none"}>
                            <p className='py-4'>
                                Nuestras soluciones son del tipo <b className='remarcado fw-bold'>extremo-a-extremo</b> pues parte con la captura del dato espacial, su procedimiento, ordenamiento
                                y análisis de acuerdo a los requerimientos del usuario final y terminan con la entrega del dato transformado en información a traves de
                                nuestra <b className='remarcado fw-bold'>plataforma basada en la web y apps</b> en una solucion de <b className='remarcado fw-bold'>alerta temprana</b> y visualización de información
                            </p>
                            <p>
                                Nuestras soluciones permiten a nuestros clientes la optimización del uso y desplieque de sus recursos en terreno, optimizando sus operaciones,
                                enfocándose solo en aquellas situaciones y lugares en las que se presentan desviaciones respecto a un comportamiento esperado, <b className='remarcado fw-bold'>reduciendo
                                    sus costos</b> y en ultimias instancia <b className='remarcado fw-bold'>maximizando sus beneficios.</b>
                            </p>
                        </div>
                        <buton className='btn rounded-1 degradado letraMontserratsemibold ' onClick={() => setMostrar(!mostrar)}>
                            {mostrar ? 'Ver Menos' : 'Ver más'}
                        </buton>
                    </div>
                    <div className='col col-sm-6 col-md-6'>
                        <img src={img1} className='img-fluid imagenIa py-2' width='100%' />
                        <img src={img2} className='img-fluid imagenIa py-2' width='100%' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InteligenciaArtificial