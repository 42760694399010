import React from 'react'

export default function Contacto() {
    return (
        <div className='card'>
            <div className='container py-3'>
                <div className='row text-primary'>
                    <div className='col-md-6 col-sm-6'>
                        <div className="input-group mb-3">
                            <span className='bi bi-person-fill'> Nombre y Apellido :</span>
                        </div>
                        <input type="text" className="form-control" placeholder="Nombre"></input>
                        <div className="input-group mb-3">
                            <span className="bi bi-envelope"> E-mail</span>
                        </div>
                        <input type="text" className="form-control" placeholder="correo" aria-label="Username" aria-describedby="basic-addon1" />
                        <div className="input-group mb-3">
                            <span className="bi bi-globe"> Website</span>
                        </div>
                        <input type="text" className="form-control" placeholder="site web" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                    <div className='col-md-6 col-sm-6'>
                        <div className="input-group mb-3">
                            <span className='bi bi-chat-left-dots-fill'> Mensaje :</span>
                        </div>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        <div className='text-center py-4'>
                            <button className='btn btn-primary' type="button">Enviar Mensaje</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
