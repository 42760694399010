import React from 'react';
import ReactDOM from 'react-dom';
//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap/dist/js/bootstrap.bundle.min.js'
//import 'bootstrap/dist/js/bootstrap.min.js'

import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
