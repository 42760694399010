import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from '../assets/Forestal.jpg'
import image2 from '../assets/Electricidad.jpg'
import image3 from '../assets/Construccion.jpg'
import image4 from '../assets/Gobierno.jpg'
import './css/CarouselMultipleCard.css'
const cards = [
    {
        id: 1,
        image: image1,
        title: "Forestal",
        descripcion: "Nuestras soluciones de monitoreo remoto del ciclo forestal combina las mas avanzadas técnicas.",
        link:'/Forestal'
    },
    {
        id: 2,
        image: image4,
        title:"Municipal y Servicios Publicos",
        descripcion: "Ayudan a municipios y empresas a incorporar sus actividades que cuenten informacion oportuna.",
        link:'/Gobierno'
    },
    {
        id: 3,
        image: image3,
        title: "Construccion",
        descripcion: "Objetivo es realizar la evaluación digital y remota del avance de una obra.",
        link:'/Construccion'
    },
    {
        id: 4,
        image: image2,
        title:"Electricidad",
        descripcion: "Ofresemos el servicio de monitoreo a lineas Eléctricas, ductos y vias férreas.",
        link:'/Electricidad'
    },
]
function CarouselMultipleCard() {
    const [visible,setVisible]=useState(true);
    var settings = {
        autoplay: false,
        dots: true,
        infinite: false,
        speed: 800,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        //nextArrow: <LeftNavButton/>,
        //prevArrow:<SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots:true,
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots:true
                }
            }
        ]
    };
    
    const mostrarLetras=(e)=>{
        var elemento=document.getElementsByClassName(e.target.id)[0];
        console.log(document.getElementById(e.target.id).firstChild);
        elemento.className=`${e.target.id} carousel-caption text-start d-md-block d-sm-block`
    }
    const esconderLetras=(e)=>{
        var elemento=document.getElementsByClassName(e.target.id)[0];
        elemento.className=`${e.target.id} carousel-caption text-start d-md-block d-sm-block invisible`
    }

    return (
        <div className='container py-4' name='industrias' id='industrias'>
            <div className='row py-2'>
                <h2 className="letraPlaneto text-dark">INDUSTRIAS QUE</h2>
                <h1 className="letraPlaneto">APOYAMOS</h1>
            </div>
            <Slider {...settings}>
                {
                    cards.map(card => (
                        <div className='carousel-item px-2 py-4' key={card.id} id={card.title} onMouseLeave={esconderLetras}>
                            <img className='img-thumbnail' src={card.image} id={card.title} onMouseOverCapture={mostrarLetras}></img>
                            <div className={`${card.title} carousel-caption text-start d-md-block d-sm-block invisible`}>
                                <h2 className='fs-2 text-white letraMontserratbold'>{card.title}</h2>
                                <p className='fs-6 text-white'>{card.descripcion}</p>
                                <Link to={card.link} className='btn btn-light rounded-1 letraMontserratsemibold'>Mas Informacion</Link>
                            </div>
                        </div>
                    ))
                }

            </Slider>
        </div>
    );
}

export default CarouselMultipleCard
