import React from 'react'
import { Carousel } from 'react-bootstrap';
import Slider from "react-slick";
import imagen1 from '../assets/inicio1.jpg'
import imagen2 from '../assets/inicio2.jpg'
import imagen3 from '../assets/inicio3.jpg'
import './css/carusel.css'

export default function Carusel() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={imagen1} className=" d-block w-100 vw-100" alt="..." />
                    <div className="carousel-caption text-start d-md-block d-sm-block">
                        <div className='py-2'>
                            <h2 className='fs-4 text-white letraMontserratbold'>Inteligencia</h2>
                            <h2 className='fs-4 text-white letraMontserratbold'>Artificial Geoespacial</h2>
                            <span className=' text-white col-md-6 d-none d-sm-inline'>{`Somos un lider regional en la combinacion de\n 
                            tecnologias geoespaciales,Inteligencia artificial \n
                            y sensores remotos para construir soluciones \n 
                            innovadores y resolver problemas desafiantes.`}</span>
                        </div>
                        <a className='btn h3 rounded-1 degradado letraMontserratsemibold' data-bs-toggle="modal" data-bs-target="#exampleModal" >Ponte en Contacto</a>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={imagen2} className="d-block d-sm-block w-100" alt="..." />
                    <div className="carousel-caption text-start d-md-block d-sm-block">
                        <div className='py-2'>
                            <h2 className='fs-4 text-white letraMontserratbold'>Transforme la productividad</h2>
                            <h2 className='fs-4 text-white letraMontserratbold'>y el valor de sus activos</h2>
                            <span className='fs-6 text-white col-md-6 d-none d-sm-inline'>{`Una plataforma de inteligencia artificial geoespacial (GeoA)\n 
                            para ayudar a nuestros partners a automatizar la loboriosa\n
                            observación manual y tomar decisiones informadas\n 
                            rapidamente.`}</span>
                        </div>
                        <a className='btn h3 rounded-1 degradado letraMontserratsemibold' data-bs-toggle="modal" data-bs-target="#exampleModal" >Ponte en Contacto</a>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={imagen3} className="d-block d-sm-block w-100" alt="..." />
                    <div className="carousel-caption text-start d-md-block d-sm-block">
                        <div className='container-fluid py-2'>
                            <h2 className='fs-4 text-white letraMontserratbold '>Decisiones poderosas</h2>
                            <h2 className='fs-4 text-white letraMontserratbold'>hechas fáciles</h2>
                            <span className='fs-6 text-white col-md-6 d-none d-sm-inline'>{`Comprenda, analice y visualice los fenómenos del\n 
                            mundo real de acuerdo con sus ubicaciones.`}</span>
                        </div>
                        <a className='btn h3 rounded-1 degradado letraMontserratsemibold' data-bs-toggle="modal" data-bs-target="#exampleModal" >Ponte en Contacto</a>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}
