import React from 'react'
import image5 from '../assets/Minera.png'
import image3 from '../assets/Codelco.png'
import image4 from '../assets/colbun.png'
import image6 from '../assets/endesa.png'
import image7 from '../assets/esval.jpg'
import image8 from '../assets/transelec.jpg'
import image1 from '../assets/mininco.png'
import image2 from '../assets/arauco.png'
import './css/clientes.css'

const clientes = [
    {
        id: 1,
        image: image1
    },
    {
        id: 2,
        image: image2
    },
    {
        id: 3,
        image: image3
    },
    {
        id: 4,
        image: image4
    },
    {
        id: 5,
        image: image5
    },
    {
        id: 6,
        image: image6
    },
    {
        id: 7,
        image: image7
    },
    {
        id: 8,
        image: image8
    }
]
export default function Clientes() {
    return (
        <div className='container py-4'>
            <div className='row py-2'>
                <h3 className='letraPP'>NUESTROS</h3>
                <h1 className='letraPlaneto'>CLIENTES</h1>
            </div>
            <div className='text-center row row-cols-2 row-cols-sm-2 row-cols-lg-6 g-1 g-lg-1 py-4'>
                {
                    clientes.map(cliente => (
                        <div className='col px-4' key={cliente.id}>
                            <img className="clientesimg img-thumbnail " src={cliente.image}/>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
