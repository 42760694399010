import React from 'react';
import './css/tipografia.css'
import './css/InicioVista.css'
import Cards from './Cards'
import Carusel from './Carusel'
import Clientes from './Clientes'
import Contacto from './Contacto'
import Header from './Header'
import CarouselMultipleCard from './CarouselMultipleCard';
import image1 from '../assets/2.jpg'

export default function InicioVista() {
    return (
        <div >
            <div className="container text-center py-2">
                <img src="/assets/img/marca.svg" className="img-fluid col-md-6 col-sm-4" alt="Responsive image" />
            </div>
            <Header />
            <Carusel />
            <div className="container-fluid">
                <div className='container  py-4'>
                    <div className='row row-col-2'>
                        <div className="col-md-6 col-sm-6 px-4">
                            <div className='container p-0 m-0 pd-6'>
                                <p className='h5 letraPP'>TRANSFORME LA</p>
                                <p className="h1 letraPlaneto">PRODUCTIVIDAD</p>
                                <p className='h4 letraPP'>Y EL VALOR DE SUS ACTIVOS</p>
                                <p className="fs-5 text-left letraMontserrat py-2">Una plataforma de inteligencia artificial geoespacial 
                                    (GeoAI) para ayudar a nuestros partners a automatizar la laboriosa observación manual y 
                                    tomar decisiones informadas rápidamente.</p>
                                <p className="fs-4 text-left letraMontserrat subtitulos"><b>Decisiones poderosas hechas fáciles.</b></p>
                                <p  className="fs-5 text-left letraMontserrat">
                                    Comprenda, analice y visualice los fenómenos del mundo real de acuerdo con sus ubicaciones.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 px-4'>
                            <img className="img-thumbnail" src={image1} />
                        </div>
                    </div>
                </div>
                <Cards />
                <CarouselMultipleCard />
                <Clientes />
            </div>
            <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header text-light">
                            <div className='row py-2'>
                                <h3 className='letraPP text-light'>PONTE EN</h3>
                                <h1 className='letraPlaneto'>CONTACTO</h1>
                            </div>
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-circle-fill"></i></button>
                        </div>
                        <div className="modal-body bg-light">
                            <Contacto />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
